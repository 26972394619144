import React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import '../../styles/components/pageFooter.scss';
import GetLinksAndLangs from '../../utils/getLinksAndLangs';
import ThemeSwitcher from '../../utils/themeSwitcher';

export default function PageFooter(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  // get the language codes and page url's (for this langauge and the alternate langauge)
  let langAndLinks = GetLinksAndLangs(site.siteMetadata.siteUrl, props.path, props.language);


  let linkTextImprint = 'Imprint';
  let linkUrlImprint = '/imprint/';
  let linkTextSwitchLanguage = 'Deutsche Version';
  let linkTextSwitchLanguageTitle = 'Diese Seite ist leider nicht auf Deutsch verfügbar...';
  let linkTextSwitchColorTheme = 'Dark / Bright';

  if (props.language === 'de') {
    linkTextImprint = 'Impressum';
    linkUrlImprint = '/de/imprint/';
    linkTextSwitchLanguage = 'English version';
    linkTextSwitchLanguageTitle = 'Sadly this page is not available in English...';
    linkTextSwitchColorTheme = 'Hell / Dunkel';
  }


  let imprintLink = (<span className="footerNavigationElement"><Link to={linkUrlImprint}>{linkTextImprint}</Link></span>);
  let languageSwitcher = (<span className="footerNavigationElement footerLanguageSwitchNotAvailable" title={linkTextSwitchLanguageTitle}>{linkTextSwitchLanguage}</span>);
  let themeSwitcher = (<span className="footerNavigationElement"><span className="jsLink" onClick={() => {switchTheme()}} onKeyDown={() => {switchTheme()}} role="button" tabIndex={0} >{linkTextSwitchColorTheme}</span></span>);

  if (props.path.startsWith("/404")) {
    // no index or follow for robots, no alternate link
  //robotsContent = 'NOINDEX, NOFOLLOW';
  } else {
    // regular page, add the alternative language information
    if (props.hasLanguageCounterpart) {
      languageSwitcher = (<span className="footerNavigationElement"><Link to={langAndLinks.otherLangPath}>{linkTextSwitchLanguage}</Link></span>);
    }
  }

  function switchTheme() {
    ThemeSwitcher();
  }

  return (
    <div id="pageFooterContainer">
      <div className="footerContent">
        {imprintLink}
        {themeSwitcher}
        {languageSwitcher}
      </div>
    </div>
  )
}
