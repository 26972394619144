import { MenuRootElement } from './types';

export const LinkPrefixGerman: string = '/de';

export const mainMenuContent: MenuRootElement[] = [
  { textEn: 'Me', textDe: 'Ich', link: '/', id: 'home', partiallyActive: true, children: [
    { textEn: 'About', textDe: 'Über mich', link: '/about-me/', id: 'about', partiallyActive: false },
    { textEn: 'My Toolbox', textDe: 'Meine Werkzeuge', link: '/about-me/my-toolbox/', id: 'toolbox', partiallyActive: false },
    { textEn: 'Other places', textDe: 'Andere Profile', link: '/about-me/other-places/', id: 'otherplaces', partiallyActive: false },
  ]},
  { textEn: 'Code & Tech', textDe: 'Code & Tech', link: '/code', id: 'code', partiallyActive: false, children: [
    { textEn: 'Ingelheim Parking Cost', textDe: 'Ingelheim Park-Kosten', link: '/code/ing-parking/', id: 'ingparking', partiallyActive: false },
  ]},
  { textEn: 'Multimedia', textDe: 'Home', link: '/multi', id: 'media', partiallyActive: false, children: [
    { textEn: 'Photography', textDe: 'Fotografie', link: '/page1/', id: 'photography', partiallyActive: false },
    { textEn: 'Video', textDe: 'Video', link: '/page2/', id: 'video', partiallyActive: false },
  ]},
];

export const themeSettings = {
  keyProperty: '--activeTheme',
  dark: [],
  light: [],
};

themeSettings.dark['--activeTheme'] = 'black';
themeSettings.dark['--background-color-main'] = 'rgb(25, 25, 25)';
themeSettings.dark['--background-color-main-alpha'] = 'rgba(25,25,25,0.75)';
themeSettings.dark['--background-color-secondary'] = 'rgb(0,0,0)';
themeSettings.dark['--background-color-secondary-alpha'] = 'rgba(0,0,0,0.75)';
themeSettings.dark['--color-main'] = 'rgb(230,230,240)';
themeSettings.dark['--color-secondary'] = 'rgb(245,245,245)';
themeSettings.dark['--color-link-text'] = 'rgb(191, 217, 255)';
themeSettings.dark['--color-logo'] = 'rgb(191, 217, 255)';
themeSettings.dark['--menu-boxshadow-color'] = 'rgba(234,234,234,0.25)';
themeSettings.dark['--menu-dropdown-color'] = 'rgba(40,40,40,0.95)';
themeSettings.dark['--menu-dropdown-hover-color'] = 'rgb(10,10,10)';
themeSettings.dark['--menu-mobile-sepearator-color'] = 'rgb(78, 92, 139)';

themeSettings.light['--activeTheme'] = 'white';
themeSettings.light['--background-color-main'] = 'rgb(225,225,225)';
themeSettings.light['--background-color-main-alpha'] = 'rgba(225,225,225,0.75)';
themeSettings.light['--background-color-secondary'] = 'rgb(180,180,180)';
themeSettings.light['--background-color-secondary-alpha'] = 'rgba(180,180,180,0.75)';
themeSettings.light['--color-main'] = 'rgb(20,20,30)';
themeSettings.light['--color-secondary'] = 'rgb(18, 26, 43)';
themeSettings.light['--color-link-text'] = 'rgb(50, 62, 100)';
themeSettings.light['--color-logo'] = 'rgb(50, 62, 100)';
themeSettings.light['--menu-boxshadow-color'] = 'rgba(10,10,10,0.25)';
themeSettings.light['--menu-dropdown-color'] = 'rgba(180,180,180,0.95)';
themeSettings.light['--menu-dropdown-hover-color'] = 'rgb(150,150,150)';
themeSettings.light['--menu-mobile-sepearator-color'] = 'rgb(18, 31, 73)';


/* list of public holidays for RPL / Ingelheim */
export const publicHolidays_RhinelandPalatinate: Date[] = [
  new Date('2020-01-01'),
  new Date('2020-04-10'),
  new Date('2020-04-13'),
  new Date('2020-05-01'),
  new Date('2020-05-21'),
  new Date('2020-06-01'),
  new Date('2020-06-11'),
  new Date('2020-10-03'),
  new Date('2020-11-01'),
  new Date('2020-12-25'),
  new Date('2020-12-26'),

  new Date('2021-01-01'),
  new Date('2021-04-02'),
  new Date('2021-04-05'),
  new Date('2021-05-01'),
  new Date('2021-05-13'),
  new Date('2021-05-24'),
  new Date('2021-06-03'),
  new Date('2021-10-03'),
  new Date('2021-11-01'),
  new Date('2021-12-25'),
  new Date('2021-12-26'),

  new Date('2022-01-01'),
  new Date('2022-04-15'),
  new Date('2022-04-18'),
  new Date('2022-05-01'),
  new Date('2022-05-26'),
  new Date('2022-06-06'),
  new Date('2022-06-16'),
  new Date('2022-10-03'),
  new Date('2022-11-01'),
  new Date('2022-12-25'),
  new Date('2022-12-26'),
];
