import React from 'react';
import { Link } from 'gatsby';
import * as pageConstants from '../../utils/constants';
import '../../styles/components/pageMenu.scss';

export default function PageMenu(language) {
  // init language values - default is english
  let activeLang = 'en';
  // image and alt texts
  // home link
  //let linkHome = '/';


  // if chinese version: override english values
  if (language === "de") {
    activeLang = 'de';
    // image and alt texts
    // home link
    //linkHome = pageConstants.LinkPrefixGerman + linkHome; // ToDo: activate if chinese lang is available
  }

  /* generate html for the desktop menu from the structure object */
  let keyCounter = 0;
  function renderDesktopMenu() {
    return (
      <ul>
        {pageConstants.mainMenuContent.map(menuItem => (
          <li className="menuMainLevel" key={keyCounter++}><Link to={menuItem.link} activeClassName="activeMenuLink" partiallyActive={menuItem.partiallyActive}>{activeLang === 'en' ? menuItem.textEn : menuItem.textDe}</Link>
            {renderSubMenu(menuItem, activeLang)}
          </li>
        ))}
      </ul>
    );
  }
  function renderSubMenu(menuItem, textLang) {
    if (typeof menuItem.children !== 'undefined') {
      return (
        <ul className="menuDesktopDropdown" aria-label="submenu">
          {menuItem.children.map(subItem => (
            <li className="menuSecondLevel" key={keyCounter++}><Link to={subItem.link} activeClassName="activeMenuLink" partiallyActive={subItem.partiallyActive}>{textLang === 'en' ? subItem.textEn : subItem.textDe}</Link></li>
          ))}
        </ul>
      );
    }
  }

  /* generate html for the mobile menu from the structure object */

  function renderMobileMenu() {
    return (
      <nav role="navigation" className="menuMobile_LinkContainer hidden" id="manuMobileMenu">
        {pageConstants.mainMenuContent.map(menuItem => (
          <div className="menuMobile_SectionContainer" key={keyCounter++}>
            <div className="menuMobile_SectionContainer_MainItem">
              <Link to={menuItem.link} className="menuMobile_SectionContainer_MainItem_Text menuMobile_LinkStyle" activeClassName="menuMobile_ActiveLinkStyle" partiallyActive={menuItem.partiallyActive}>{activeLang === 'en' ? menuItem.textEn : menuItem.textDe}</Link>
              {renderMobileExpandButton(menuItem)}
            </div>
            {renderMobileSubSection(menuItem, activeLang)}
          </div>
        ))}
      </nav>
    );
  }

  function renderMobileExpandButton(menuItem) {
    if (typeof menuItem.children !== 'undefined') {
      const elementId = 'manuMobileMenu_' + menuItem.id +  '_Icon';
      return (
        <div className="menuMobile_SectionContainer_MainItem_Icon" id={elementId} onClick={() => menuSectionToggle(menuItem.id)} onKeyDown={() => menuSectionToggle(menuItem.id)} role="button" tabIndex={0} aria-label="Toggle button ot open / close the sub-menu">
          <div className="manuMobileMenu_DropdownIcon_SvgHolder">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M160 256.14l-56.51 56.47-96.44-96.15a23.77 23.77 0 0 1-.18-33.61l.18-.18 22.59-22.51a23.94 23.94 0 0 1 33.85 0z" className="menu_DropdownArrow_Secondary"/>
              <path d="M313 182.57L290.21 160a23.94 23.94 0 0 0-33.85 0L103.47 312.61 143 352l.06.06a24 24 0 0 0 33.93-.16L313 216.36l.18-.17a23.78 23.78 0 0 0-.18-33.62z" className="menu_DropdownArrow_Primary"/>
            </svg>
          </div>
        </div>
      );
    }
  }

  function renderMobileSubSection(menuItem, textLang) {
    if (typeof menuItem.children !== 'undefined') {
      const sectionElementId = 'manuMobileMenu_' + menuItem.id +  '_Section';
      return (
        <div className="menuMobile_SectionContainer_SubItems hidden" id={sectionElementId}>
          {menuItem.children.map(subItem => (
            <div className="menuMobile_SectionContainer_SubItems_Element" key={keyCounter++}>
              <Link to={subItem.link} className="menuMobile_LinkStyle" activeClassName="menuMobile_ActiveLinkStyle" partiallyActive={menuItem.partiallyActive}>{textLang === 'en' ? subItem.textEn : subItem.textDe}</Link>
            </div>
          ))}
        </div>
      );
    }
  }

  /* mobile menu functions called by above generated code */

  function menuToggle() {
    const burgerElement = document.getElementById("manuMobileBurger");
    const closeElement = document.getElementById("manuMobileCloser");
    const contentElement = document.getElementById("manuMobileMenu");
    if (burgerElement.className === "menuMobile_BurgerButton") {
      burgerElement.className += " hidden";
      closeElement.className = "";
      contentElement.className = "menuMobile_LinkContainer";
    } else {
      burgerElement.className = "menuMobile_BurgerButton";
      closeElement.className = "hidden";
      contentElement.className += " hidden";
    }
  }

  function menuSectionToggle(baseId) {
    let sectionIcon = document.getElementById("manuMobileMenu_" + baseId + "_Icon");
    let sectionContainer = document.getElementById("manuMobileMenu_" + baseId + "_Section");
    if (sectionContainer.className === "menuMobile_SectionContainer_SubItems") {
      sectionContainer.className += " hidden";
      sectionIcon.className = "menuMobile_SectionContainer_MainItem_Icon";
    } else {
      sectionContainer.className = "menuMobile_SectionContainer_SubItems";
      sectionIcon.className += " menuMobile_Icon_Rotated";
    }
  }

  /* final rendering / returning of the menu html */
  return (
    <header id="pageMenuHeaderContainer">


      <div className="pageMenuContent">
        <div className="headerLogo">
          <div className="logoContainer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 339.73 214.02">
              <line className="logoManuLineStyle" x1="15.34" y1="25.69" x2="15.34" y2="192.36"/>
              <line className="logoManuLineStyle" x1="165.7" y1="25.69" x2="165.7" y2="102.8"/>
              <line className="logoManuLineStyle" x1="158.78" y1="23.61" x2="99.82" y2="82.57"/>
              <circle className="logoManuLineStyle" cx="15.34" cy="15.34" r="10.34"/>
              <circle className="logoManuLineStyle" cx="15.34" cy="198.68" r="10.34"/>
              <circle className="logoManuLineStyle" cx="165.7" cy="15.34" r="10.34"/>
              <circle className="logoManuLineStyle" cx="324.38" cy="15.34" r="10.34"/>
              <circle className="logoManuLineStyle" cx="91.84" cy="90.14" r="10.34"/>
              <circle className="logoManuLineStyle" cx="42.45" cy="40.78" r="10.34"/>
              <circle className="logoManuLineStyle" cx="66.96" cy="65.3" r="10.34"/>
              <line className="logoManuLineStyle" x1="49.77" y1="48.1" x2="59.65" y2="57.98"/>
              <circle className="logoManuLineStyle" cx="165.7" cy="198.68" r="10.34"/>
              <circle className="logoManuLineStyle" cx="165.7" cy="113.14" r="10.34"/>
              <circle className="logoManuLineStyle" cx="165.7" cy="148.84" r="10.34"/>
              <line className="logoManuLineStyle" x1="165.7" y1="159.18" x2="165.7" y2="188.33"/>
              <circle className="logoManuLineStyle" cx="217.78" cy="198.68" r="10.34"/>
              <polyline className="logoManuLineStyle" points="217.78 189.08 217.78 127.71 233.88 112.59 264.34 112.59"/>
              <circle className="logoManuLineStyle" cx="274.01" cy="113.14" r="10.34"/>
              <polyline className="logoManuLineStyle" points="221.73 72.68 221.73 30.31 237.82 15.2 314.28 15.2"/>
              <circle className="logoManuLineStyle" cx="221.73" cy="82.57" r="10.34"/>
              <path className="logoManuLineStyle" d="M16.22-12.3" transform="translate(-0.87 -1.18)"/>
            </svg>
          </div>

        </div>


        <div className="menuDesktopContent">
          <div className="menuDesktop">
            <nav role="navigation">
              {renderDesktopMenu()}
            </nav>
          </div>
        </div>
      </div>
      <div className="menuMobileContent">
        <div className="menuMobile_BurgerButton" id="manuMobileBurger" onClick={() => menuToggle()} onKeyDown={() => menuToggle()} role="button" tabIndex={0}>≡</div>
        <div className="hidden" id="manuMobileCloser" onClick={() => menuToggle()} onKeyDown={() => menuToggle()} role="button" tabIndex={0}>
          <div className="menuMobile_CloseButton">
            <span className="menuMobile_CloseText">close </span><span className="menuMobile_TimesSymbol">&times;</span>
          </div>
        </div>
        <div className="stopFloat"></div>
        {renderMobileMenu()}
      </div>

    </header>
  );
}
