import * as pageConstants from './constants';

//getComputedStyle(document.documentElement).getPropertyValue('--my-variable-name'); // #999999
// document.documentElement.style.setProperty('--my-variable-name', 'pink');

export default function ThemeSwitcher(): void {
  if (typeof document !== 'undefined') {
    const currentTheme = getComputedStyle(document.documentElement).getPropertyValue(pageConstants.themeSettings.keyProperty).trim();
    let newValues;
    if (currentTheme === pageConstants.themeSettings.dark[pageConstants.themeSettings.keyProperty]) {
      // is dark theme, so make it bright
      //console.log('is dark');
      newValues = pageConstants.themeSettings.light;
    }
    else {
      // is bright theme, so make it dark
      //console.log('is light');
      newValues = pageConstants.themeSettings.dark;

    }
    // apply
    for (const key in newValues) {
      document.documentElement.style.setProperty(key, newValues[key]);
    }


  }
}
