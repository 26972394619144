import React from 'react';
//import { PageProps } from "gatsby";
import PageMenu from '../pageMenu/pageMenu';
import PageFooter from '../pageFooter/pageFooter';
import Meta from '../meta/meta';

export default function Layout(props) {
  return (
    <div>
      <Meta title={props.title} description={props.description} lang={props.language} path={props.path} previewimage={props.previewImage} hasLanguageCounterpart={props.hasLanguageCounterpart} />
      <div className="ieWarning">
        <div style={{position:'absolute',zIndex: 99999,display:'block',fontSize:'30px',width:'100%',textAlign:'center',background:'#fff',opacity:'0.9',height:'100vh'}}><div style={{padding:'50px',fontWeight:'bold'}}>Sorry, Internet Explorer and the old Edge Versions are not supported...</div><div>Please use an up-to-date browswer like Firefox to visit this website.</div></div>
      </div>
      <PageMenu language={props.language} />
      <div className="contentContainer">
        <div className="edgeWarning">
          <h4><span role="img" aria-label="warning icon">⚠️</span> You are using an old version of Edge. <span role="img" aria-label="warning icon">⚠️</span></h4>
          <p>This browser can not support the full functionality of this website. For a better user experience we recommend to use the latest version of Edge or other up-to-date Browsers like Firefox, Chrome or Opera...</p>
        </div>
        <main>
          {props.children}
        </main>
      </div>
      <PageFooter language={props.language} path={props.path} hasLanguageCounterpart={props.hasLanguageCounterpart} />
    </div>
  )
}
