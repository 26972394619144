import * as pageConstants from './constants';
import { CrossLanguageInformation } from './types';

// function to generate full links for current language and the second language
export default function GetLinksAndLangs (pageurl: string, path: string, lang: string): CrossLanguageInformation {
  // init result object
  let result: CrossLanguageInformation = {
    langCode: '',
    otherLangCode: '',
    langUrl: '',
    langPath: '',
    otherLangUrl: '',
    otherLangPath: '',
  };
  // set values
  result.langCode = (lang === 'de') ? 'de' : 'en';
  result.otherLangCode = (lang === 'de') ? 'en' : 'de';
  result.langUrl = pageurl + path;
  result.langPath = path;
  if (lang === 'de') {
    result.otherLangUrl = pageurl + path.replace(pageConstants.LinkPrefixGerman, '');
    result.otherLangPath = path.replace(pageConstants.LinkPrefixGerman, '');
  } else {
    result.otherLangUrl = pageurl + pageConstants.LinkPrefixGerman + path;
    result.otherLangPath = pageConstants.LinkPrefixGerman + path;
  }
  return result;
}
