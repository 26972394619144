import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import GetLinksAndLangs from '../../utils/getLinksAndLangs';

function Meta({ description, lang, title, path, previewimage, hasLanguageCounterpart }) {
  // query global metadata from gatsby-config.js
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            publisher
            pageTopic
            pageType
            copyright
            audience
            msValidate
            googleVerify1
            googleVerify2
            yandexVerify
            appleStatusBarStyle
            themeColor
          }
        }
      }
    `
  );

  // get the language codes and page url's (for this langauge and the alternate langauge)
  let langAndLinks = GetLinksAndLangs(site.siteMetadata.siteUrl, path, lang);
  // prepare the object that contains all seo relevant link tags in the header
  let headerLinksObject: any[] = [
    {
      rel: `canonical`,
      href: langAndLinks.langUrl,
    }
  ];
  // default the robots instructions
  let robotsContent = 'INDEX, FOLLOW';
  // check if 404 or not - using startswith because it can be called by /404 or /404.html
  if (path.startsWith("/404")) {
    // no index or follow for robots, no alternate link
    robotsContent = 'NOINDEX, NOFOLLOW';
  } else {
    // regular page, add the alternative language information
    if (hasLanguageCounterpart) {
      headerLinksObject.push({
        rel: `alternate`,
        hreflang: langAndLinks.otherLangCode,
        href: langAndLinks.otherLangUrl,
      });
    }
  }

  // copy the current language code to access it further below (since object.property does not work there)
  let languageCode = langAndLinks.langCode;

  // get description text for page or fall back to default
  const metaDescription = description || site.siteMetadata.description;

  // icons and related files
  headerLinksObject.push({ rel: `icon`, type: `image/x-icon`, href: `/favicon.ico` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `57x57`, href: `/apple-icon-57x57.png` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `60x60`, href: `/apple-icon-60x60.png` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `72x72`, href: `/apple-icon-72x72.png` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `76x76`, href: `/apple-icon-76x76.png` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `114x114`, href: `/apple-icon-114x114.png` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `120x120`, href: `/apple-icon-120x120.png` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `144x144`, href: `/apple-icon-144x144.png` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `152x152`, href: `/apple-icon-152x152.png` });
  headerLinksObject.push({ rel: `apple-touch-icon`, sizes: `180x180`, href: `/apple-icon-180x180.png` });
  headerLinksObject.push({ rel: `icon`, type: `image/png`, sizes: `16x16`, href: `/favicon-16x16.png` });
  headerLinksObject.push({ rel: `icon`, type: `image/png`, sizes: `32x32`, href: `/favicon-32x32.png` });
  headerLinksObject.push({ rel: `icon`, type: `image/png`, sizes: `96x96`, href: `/favicon-96x96.png` });
  headerLinksObject.push({ rel: `icon`, type: `image/png`, sizes: `192x192`, href: `/android-icon-192x192.png` });
  headerLinksObject.push({ rel: `manifest`, href: `/manifest.json` });

  let metaTags = [
    {name: `mobile-web-app-capable`, content: `yes`},
    {name: `apple-mobile-web-app-capable`, content: `yes`},
    {name: `apple-mobile-web-app-status-bar-style`, content: site.siteMetadata.appleStatusBarStyle},
    {name: `msapplication-TileImage`, content: `/ms-icon-144x144.png`},
    {name: `msapplication-TileColor`, content: site.siteMetadata.themeColor},
    {name: `theme-color`, content: site.siteMetadata.themeColor},
    {name: `description`, content: metaDescription},
    {name: `page-topic`, content: site.siteMetadata.pageTopic},
    {name: `page-type`, content: site.siteMetadata.pageType},
    {name: `author`, content: site.siteMetadata.author},
    {name: `publisher`, content: site.siteMetadata.publisher},
    {name: `copyright`, content: site.siteMetadata.copyright},
    {name: `audience`, content: site.siteMetadata.audience},
    {name: `robots`, content: robotsContent},
    {name: `language`, content: langAndLinks.langCode},
    {property: `og:title`, content: title},
    {property: `og:description`, content: metaDescription},
    {property: `og:type`, content: `website`},
    {property: `og:site_name`, content: metaDescription},
    {property: `og:locale`, content: languageCode},
    //{property: `og:locale:alternate`, content: `xxx`}, // ToDo: reactivate once we have german version
    {name: `twitter:creator`, content: site.siteMetadata.author},
    {name: `twitter:url`, content: langAndLinks.langUrl},
    {name: `twitter:title`, content: title},
    {name: `twitter:description`, content: metaDescription},
  ];

  if (site.siteMetadata.msValidate !== '') {
    metaTags.push({name: 'msvalidate.01', content: site.siteMetadata.msValidate});
  }
  if (site.siteMetadata.googleVerify1 !== '') {
    metaTags.push({name: 'google-site-verification', content: site.siteMetadata.googleVerify1});
  }
  if (site.siteMetadata.googleVerify2 !== '') {
    metaTags.push({name: 'google-site-verification', content: site.siteMetadata.googleVerify2});
  }
  if (site.siteMetadata.yandexVerify !== '') {
    metaTags.push({name: 'yandex-verification', content: site.siteMetadata.yandexVerify});
  }



  if (typeof previewimage !== 'undefined') {
    const previewUrl = site.siteMetadata.siteUrl + '/images/previewimages/' + previewimage.imageFile;
    let imageType = 'image/';
    let unknownType = false;
    if (previewimage.imageFile.endsWith('.png')) {imageType += 'png'}
    else if (previewimage.imageFile.endsWith('.gif')) {imageType += 'gif'}
    else if (previewimage.imageFile.endsWith('.jpg') || previewimage.imageFile.str.endsWith('.jpeg')) {imageType += 'jpeg'}
    else {
      unknownType = true;
    }
    if (!unknownType) {
      metaTags.push({property: `og:image`, content: previewUrl});
      metaTags.push({property: `og:image:url`, content: previewUrl});
      metaTags.push({property: `og:image:secure_url`, content: previewUrl});
      metaTags.push({property: `og:image:alt`, content: previewimage.imageDescription});
      metaTags.push({property: `og:image:width`, content: previewimage.imageWidth});
      metaTags.push({property: `og:image:height`, content: previewimage.imageHeight});
      metaTags.push({property: `og:image:type`, content: imageType});
      metaTags.push({name: `twitter:image`, content: previewUrl});
      metaTags.push({name: `twitter:card`, content: `summary_large_image`});
    } else {
      metaTags.push({name: `twitter:card`, content: `summary`});
    }
  } else { // if no image is passed at all
    metaTags.push({name: `twitter:card`, content: `summary`});
  }

/*
// maxlength of title = 55 / 95 Twitter & OpenGraph
// maxlength of description = 156 / 140 Twitter / 297 OpenGraph
*/
  return (
    <Helmet
      htmlAttributes={{
        lang: languageCode,
      }}
      link={headerLinksObject}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaTags}
    />
  )
}

Meta.defaultProps = {
  lang: `en`,
  description: ``,
  hasLanguageCounterpart: true,
}

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  previewimage: PropTypes.object,
  hasLanguageCounterpart: PropTypes.bool,
}

export default Meta;
